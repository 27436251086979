//导入axios请求的方法
import request from '../utils/request'

//封装用户信息的请求方法
export function saveUserInfo(visitorInfo){
	request.post('/saveVisitInfo',visitorInfo)
}

export function getUserInfo(params){
	return request.get('/getUserInfo',{ params })
	// .then(data=>{
	// 	visitorName: data.data.visitorName,
	// 	phoneNumber: data.data.phoneNumber,
	// 	idCard: data.data.idCard,
	// 	companyName: data.data.companyName,
	// })
}

// const params = {
//   openId: "just"
// };
// export function getUserInfo(params){
// 	// console.log(openId)
// 	// request.get('/getUserInfo',openId).then(data=>{
// 	// 	console.log(data)
// 	// })
// 	// console.log(openId)
// 	console.log(params)
// 	request.get('/why', { params })
// 	  .then(response => {
// 	    console.log('GET请求成功', response.data);
// 	  })
// 	  .catch(error => {
// 	    console.error('GET请求失败', error);
// 	  });
// }
