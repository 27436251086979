import Vue from 'vue';
import { Tabbar, TabbarItem,Cell, CellGroup ,Field ,Form ,NavBar,Button,Popup,Picker,DatetimePicker,Dialog,Toast , Card ,Grid, GridItem ,PullRefresh,Image       } from 'vant';
import 'vant/lib/index.css';

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Form);
Vue.use(NavBar);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Dialog); 
Vue.use(Toast); 
Vue.use(Card); 
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(PullRefresh);
Vue.use(Image);
// Vue.use(RadioGroup);
// Vue.use(Radio);
// Vue.use(DatetimePicker);
// Vue.use(Select);
// Vue.use(Option);
// Vue.use(ContactCard);