import request from '../utils/request.js'

export function getOpenIdByCode(params){
	return request.get('/getOpenIdByCode',{params});
}

export  function isUserSubscribed(params){
	 return request.get("/isUserSubscribed",{params})
}

export  function getIsUserInfoFlag(params){
	 return request.get("/getIsUserInfoFlag",{params})
}