import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BookView from '../views/BookView.vue'
import BookListView from '../views/BookListView.vue'
import BookDetailView from '../views/BookDetailView.vue'
import UserInfoView from '../views/userInfoView.vue'

import TestView from '../views/test.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    //name: 'home',
    component: HomeView
  },
  {
    path: '/book',
    name: 'book',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BookView
  },
  {
    path: '/booklist',
    name: 'booklist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BookListView
  },
  {
    path: '/bookdetail/:id',
    name: 'bookdetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BookDetailView
  },
  ,
  {
    path: '/userInfo',
    name: 'userInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UserInfoView
  },
  ,
  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TestView
  }
]

const router = new VueRouter({
  routes
})

export default router
