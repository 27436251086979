<template>
  <div class="appointment-record">
    <!-- 页面标题 -->
    <!--<div class="title">预约记录</div>-->

    <!-- 预约记录列表 -->
    <van-grid :column-num="1">
      <van-grid-item v-for="(record, index) in displayedRecords" :key="index">
		 
        <van-card class="card" :style="{ width: 'calc(100% - 20px)' }" @click="goToDetailPage(record)">
			 
          <template #title>
			<div  class="container record-title" :class="{'container-color-green':record.appointmentStatus!=='拒绝','container-color-red':record.appointmentStatus ==='拒绝'}">
				<span style="float: left;margin-left: 1rem; vertical-align: middle;">{{ record.VisitorNumber }}</span>
				<span style="float: right;margin-right: 1rem; vertical-align: middle;">{{ record.appointmentStatus }}</span>
		    </div>
		  </template>
          <template #desc>
            <div class="record-info">
              <label class="label">被访人员：</label><span class="text">{{ record.visitedPerson }}</span>
            </div>
            <div class="record-info">
              <label class="label">访问时间：</label><span class="text">{{ record.visitTime }}</span>
            </div>
            <div class="record-info">
              <label class="label">预约人员：</label><span class="text">{{ record.appointmentPerson }}</span>
            </div>
            
            <van-button v-if="record.appointmentStatus === '预约申请中' || record.appointmentStatus === '预约完成'"
                        @click.stop="showCancelDialog(record,index)"
                        plain
                        color="red"
                        size="small"
            >
              取消预约
            </van-button>
			
          </template>
        </van-card>
      </van-grid-item>
    </van-grid>

    <!-- 取消预约的确认对话框 -->
    <van-dialog
      v-model:show="cancelDialogVisible"
      title="取消预约"
      show-confirm-button
      confirm-button-text="确认"
      show-cancel-button
      cancel-button-text="取消"
      @confirm="cancelAppointment"
    >
      <van-field v-model="cancelReason" label="取消原因" placeholder="请输入取消预约的原因" />
    </van-dialog>

    <!-- 上拉加载更多 -->
    <van-pull-refresh v-model="isLoading" @refresh="loadMore" style="position: fixed; bottom: 0; width: 100%" />

  </div>
</template>

<script>
	import { Toast } from 'vant';
import{getVisitDetailsByOpenId,cancelVisitDetail} from '../api/bookList.js'
export default {
  data() {
    return {
      appointmentRecords: [
        // { visitedPerson: '被访人A', visitTime: '2024-03-15 10:00', appointmentPerson: '预约人A', appointmentStatus: '预约申请中' },
        // { visitedPerson: '被访人B', visitTime: '2024-03-16 11:00', appointmentPerson: '预约人B', appointmentStatus: '预约完成' },
        // { visitedPerson: '被访人C', visitTime: '2024-03-17 12:00', appointmentPerson: '预约人C', appointmentStatus: '核验入厂' },
        // 其他预约记录...
      ],
      cancelDialogVisible: false,
      cancelVisitDetail: null,
      cancelReason: '',
      displayedRecords: [],
      isLoading: false ,// 是否正在加载更多数据
	  openId:null,
	  cancelIndex:null,
    };
  },
  created() {
  	const storedObj = localStorage.getItem('openId');
  	let openId = storedObj;
  	if(!openId){
		Toast.fail("未成功获取到openId,请检查是否关注公众号！");
  		this.$router.push({path:'/'})
  	}
  	// this.$router.push({path:'/'})
  	this.openId = openId;
  },
  
  mounted() {
	this.load();
    this.loadMore();
  },
  methods: {
	async load(){
		const res = await getVisitDetailsByOpenId({openId:this.openId});
		if(res.data.code=200){
			const obj = res.data.data;
			const visitDetails = obj.visitDetails;

			visitDetails.map(item=> {
				if(item.visitStatus == "W"){
					item.visitStatus ="预约申请中"
				}
				if(item.visitStatus == "P"){
					item.visitStatus ="预约完成"
				}
				if(item.visitStatus == "S"){
					item.visitStatus ="核验入厂"
				}
				if(item.visitStatus == "O"){
					item.visitStatus ="已离场"
				}
				if(item.visitStatus == "R"){
					item.visitStatus ="拒绝"
				}
				if(item.visitStatus == "C"){
					item.visitStatus ="取消"
				}
			})
 
			
			this.$data.displayedRecords = visitDetails.map(item=>(
			{
				VisitorNumber:item.visitorNumber,
				visitDetailId:item.visitDetailId,
				visitedPerson : item.hostName,
				visitTime:item.visitTime,
				appointmentPerson:obj.visitorName,
				appointmentStatus:item.visitStatus,
			}))
		}
	},  
    showCancelDialog(index,cancelindex) {
	  this.cancelIndex = cancelindex;
	  
      this.cancelVisitDetail = index;
      this.cancelDialogVisible = true;
    },
    async cancelAppointment() {
      if (this.cancelReason.trim()) {
        //this.displayedRecords[this.cancelIndex].appointmentStatus = '取消';
        //this.displayedRecords[this.cancelIndex].cancelReason = this.cancelReason;
		this.$data.cancelVisitDetail.cancelReason = this.cancelReason;
		
		console.log(this.$data.cancelVisitDetail)
		
		const res = await cancelVisitDetail(this.$data.cancelVisitDetail);
		
		if(res.data.code===200){
			this.cancelDialogVisible = false;
			this.cancelReason = '';
			const cancelVisitDetail =  this.displayedRecords[this.cancelIndex];
			cancelVisitDetail.appointmentStatus="取消";
		}
		
        
      } else {
        this.$toast.fail('取消原因不能为空');
      }
    },
    loadMore() {
      const lastIndex = this.displayedRecords.length;
      const newRecords = this.appointmentRecords.slice(lastIndex, lastIndex + 10);
      this.displayedRecords = this.displayedRecords.concat(newRecords);
    },
	goToDetailPage(recode){
		console.log(recode)
		this.$router.push({ name: 'bookdetail', params: { id: recode.visitDetailId } });
	}
	
	
  }
};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.container {
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center; /* 垂直居中对齐 */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.container-color-green{
	background-color: #95EC69;
}
.container-color-red{
	background-color: red;
}

.card {
  margin-bottom: 10px;
  /*padding: 20px;*/
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.van-card {
    position: relative;
    box-sizing: border-box;
	margin: 8px 16px;
    color: #323233;
    font-size: 12px;
    background-color: #fafafa;
}

.record-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.record-info {
  font-size: 16px;
  margin-bottom: 8px;
  
  display: flex; /* 使用 Flex 布局 */
  padding: 0px 16px;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}

.text {
  flex: 1; /* 让文本占据剩余空间 */
}
<style scoped>
.title-container {
  width: 100%;
}

.title-content {
  width: 100%;
}
</style>
