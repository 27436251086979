<template>
  <div>
	  <div v-if="keyboardOpen" class="keyboard-overlay" @click="closeKeyBoard"></div>
    <van-form @submit="onSubmit" :class="{ 'keyboard-open': keyboardOpen }">
		<van-field
		v-model="visitor.hostPhone" 
		label="被访人手机" 
		type="tel"
		@input="handleVisitorPhoneChange" required
		:rules="[{ pattern, message: '请输入正确内容' }]"></van-field>
		<van-field v-model="visitor.hostName" label="被访人姓名" placeholder="根据手机号由系统自动带出"  readonly required></van-field>
		<van-field v-model="visitor.hostDepartment" label="被访人部门" placeholder="根据手机号由系统自动带出" readonly required></van-field>
      <van-field v-model="visitor.numberOfVisitors" label="来访人数" type="number" required></van-field>
      <van-field v-model="visitor.appointment" clickable readonly @click="showAppointment" label="是否预约" placeholder="请选择" required></van-field>
      
	  <van-field v-model="visitor.enterWorkShop" clickable readonly @click="showEnterWorkShop" label="是否进入车间" placeholder="请选择" required></van-field>
	  
      <van-field
              v-model="visitor.visitTime"
              label="来访时间"
              clickable
              readonly
              placeholder="请选择"
              @click="showDatetimePicker_true" required
            ></van-field>
      <van-field
            v-model="visitor.visitPurpose"
            label="来访事由"
            clickable
            readonly
            placeholder="请选择" 
            @click="showPopup" required
          ></van-field>
      <van-field class="input-field" v-model="visitor.personalDevices" label="自带设备" placeholder="如没有请填写无" required></van-field>
	  <van-field
	    v-model="visitor.licensePlate"
	    label="车牌号码"
	    placeholder="如没有请填写无"
	    readonly
		required
	    @click="showPlateKeyboard_F"
	  />
	  <!-- 车牌号输入组件 -->
	  <input-plate-number
	    v-if="showPlateKeyboard"
		class="plate-keyboard"
		@click.stop
	    @input="onInput"
	    @key-click="onInput" @change-energy="changeEnergy"
		@del-click="onInput"
		@close="closeKeyBoard"
	  />
      <!-- <van-field class="input-field" v-model="visitor.licensePlate" label="车牌号码" placeholder="如没有请填写无" required></van-field> -->
      <van-field
            
            label="随访人员"
            readonly
            clickable
            placeholder="点击添加随访人员"
            @click="showFollowersPopup"
          ></van-field>
       <van-cell-group>
          <van-cell
            v-for="(follower, index) in visitor.followers"
            :key="index"
           :title="`${follower.followerName} - ${follower.followerIdNumber}`"
            is-link
          />
        </van-cell-group>
		<div class="bold-text">访客须知内容 1、入厂前请携带有效证件 2、谢绝摄影/拍照 
3、入厂后请佩戴访客证 4、需由我司人员带领陪同 5、已阅读访客须知
</div>
      <van-button type="primary" native-type="submit" :disabled="isSubmitting || keyboardOpen " round style="width: 80%; margin-top: 20px;">提交</van-button>
      

	  
	  <!-- 是否预约选择器 -->
	  <van-popup v-model="showAppointment_flag" position="bottom">
	    <van-picker
	      show-toolbar
	      :columns="AppointmentOptions"
	      @cancel="cancelAppointment"
	      @confirm="confirmAppointment"
	    />
	  </van-popup>
	  
	  <!-- 是否预约需进入车间选择器 -->
	  <van-popup v-model="showEnterWorkShop_flag" position="bottom">
	    <van-picker
	      show-toolbar
	      :columns="EnterWorkShopOptions"
	      @cancel="cancelEnterWorkShop"
	      @confirm="confirmEnterWorkShop"
	    />
	  </van-popup>
	  
	  <van-popup v-model="datetimePickerShow" position="bottom">
	  <!-- DatetimePicker 组件 -->
	      <van-datetime-picker
	        v-model="datetimePickerValue"
	        type="datetime"
	        :min-date="minDate"
	        :max-date="maxDate"
	        :formatter="formatter"
	        :show="datetimePickerShow"
	        @confirm="onDatetimeConfirm"
	        @cancel="onDatetimeCancel"
	      ></van-datetime-picker>
		  </van-popup>
		  
		  <van-popup v-model="popupVisible" position="bottom">
		        <van-picker
		          show-toolbar
		          :columns="visitPurposeOptions"
		          @cancel="hidePopup"
		          @confirm="onPickerConfirm"
		        ></van-picker>
		      </van-popup>
			  
    <!-- Popup 组件 -->

    </van-form>
	<van-popup v-model="followersPopupVisible" position="bottom">
	      <div class="popup-content">
	        <!-- 随访人员列表 -->
	<!--        <van-cell-group>
	          <van-cell
	            v-for="(follower, index) in visitor.followers"
	            :key="index"
	           :title="`${follower.name} - ${follower.idCard}`"
	            is-link
	          />
	        </van-cell-group> -->
	
	        <!-- 随访人员信息表单 -->
	        <van-field v-model="newFollower.followerName" label="姓名" placeholder="请输入姓名"></van-field>
	        <van-field v-model="newFollower.followerIdNumber" label="身份证号" placeholder="请输入身份证号"></van-field>
	
	        <!-- 添加随访人员按钮 -->
	        <van-button type="primary" @click="addFollower">添加随访人员</van-button>
	        <!-- 关闭 Popup 按钮 -->
	        <!-- <van-button @click="closeFollowersPopup">关闭</van-button> -->
	      </div>
	    </van-popup>
  </div>
  
</template>

<script>
	
import { Toast } from 'vant';
import {getVisitPurposeList, getWxUserInfo, submitFrom,getVisitorInfo} from '../api/bookView.js'  
import InputPlateNumber from "../components/input-plate-number/src/input-plate-number.vue";
export default {
  data() {
    return {
      visitor: {
        companyName: '',
        name: '',
        numberOfVisitors: 1,
        validID: '',
        idNumber: '',
        appointment: '',
        hostPhone: '',
        hostName: '',
        hostDepartment: '',
        visitTime: '',
        visitPurpose: '',
		visitPurposeId: '',
        personalDevices: '',
        licensePlate: '',
        followers: [],
		wxUserId:'',
		openId:null,
		employeeNumber:'',
		enterWorkShop:'',
      },
	  newFollower: '',
	  dialogVisible: false,
	  isSubmitting: false,
	  AppointmentOptions: [
	    { text: '是', value: '是' },
	    { text: '否', value: '否' },
	  ],
	  showAppointment_flag:false,
	  EnterWorkShopOptions: [
	    { text: '是', value: '是' },
	    { text: '否', value: '否' },
	  ],
	  showEnterWorkShop_flag:false,
	  datetimePickerValue: new Date(),
	  datetimePickerShow: false,
	  minDate: new Date(), // 可根据实际需求设置最小日期
	  maxDate: new Date(2050, 11, 31), // 可根据实际需求设置最大日期
			
	  visitPurposeOptions: [],
	  popupVisible: false,	
	  followersPopupVisible:false,
	  newFollower: {
		followerName: '',
		followerIdNumber: '',
	  },
	  pattern: /^\d{11}$/,
	  showPlateKeyboard: false, // 控制键盘显示
	  licensePlateLength:7,
	  keyboardOpen:false,
    };
  },
  components: {
    InputPlateNumber,
  },
  created() {
  	const storedObj = localStorage.getItem('openId');
  	let openId = storedObj;
  	if(!openId){
		Toast("获取openId失败，即将返回首页~")
  		this.$router.push({path:'/'})
  	}
  	// this.$router.push({path:'/'})
  	this.visitor.openId = openId;
  },
  mounted() {
  	this.load();
  },
  methods: {
	  handleVisitorPhoneChange() {
		  console.log(this.visitor.hostPhone)
		// 在输入框的值变化时触发
		// 此处可以进行手机格式验证等逻辑
		if (this.isValidPhoneNumber(this.visitor.hostPhone)) {
		  // 格式正确，向后台发起请求
		  this.sendRequestToBackend();
		}else{
			this.visitor.wxUserId=''
			this.visitor.hostName =''
			this.visitor.hostDepartment = ''
		}
	  },
	  isValidPhoneNumber(phoneNumber) {
		// 进行手机格式验证的逻辑
		// 你可以使用正则表达式等方式进行验证
		// 此处只是简单的示例
		return /^\d{11}$/.test(phoneNumber);
	  },
	  async sendRequestToBackend() {
		
		const userInfo =await  getWxUserInfo({phoneNumber:this.visitor.hostPhone});
		if(userInfo.data.success){
			this.visitor.wxUserId=userInfo.data.data.userid
			this.visitor.hostName = userInfo.data.data.name
			this.visitor.hostDepartment = userInfo.data.data.departmentName
			this.visitor.employeeNumber = userInfo.data.data.employeeNumber
		}
		if(!userInfo.data.success){
			Toast(userInfo.data.msg)
		}
	  },
	  showFollowersPopup() {
		this.followersPopupVisible = true;
	  },
	  addFollower() {
		// 将新的随访人员信息添加到列表
		this.visitor.followers.push({
		  followerName: this.newFollower.followerName,
		  followerIdNumber: this.newFollower.followerIdNumber,
		});
	  
		// 清空输入框
		this.newFollower = {
		  name: '',
		  idCard: '',
		};
		this.followersPopupVisible = false;
	  },
	  async load(){
		
		const res =  await getVisitorInfo({openId:this.visitor.openId});
		if(res.data.code=200){
			this.visitor.followers.push({
			  followerName: res.data.data.visitorName,
			  followerIdNumber: res.data.data.idCard,
			});
		}
		  
	  	const obj = await getVisitPurposeList();
	  	const  visitorInfo = obj.data.data
		this.visitPurposeOptions =visitorInfo
		 this.$data.visitPurposeOptions = visitorInfo.map(item => ({
            text: item.visitPurpose,
            value: item.visitPurposeId,
          }));
	  	// this.$data.visitorName = obj.data.data.visitorName
	  	// this.$data.phoneNumber = obj.data.data.phoneNumber
	  	// this.$data.idCard = obj.data.data.idCard
	  	// this.$data.companyName = obj.data.data.companyName
	  },
	  

	  
    async onSubmit() {
		if (this.isSubmitting) {
		        return;
		      }
		this.isSubmitting = true;
      // 处理表单提交逻辑1
		  console.log('Form submitted:', this.visitor);
		  
		  if(!this.checkform()){
		  		  this.isSubmitting = false;
		  		  return 
		  }
		  const result = await submitFrom(this.visitor);
		  if(result.data.code==200){
		  		  Toast.success("预约成功");
		  		  this.$router.push({path:'/'})
		  		  this.resetForm();
		  }
	  this.isSubmitting = false;
    },
	
	checkform(){
		if(!this.visitor.hostPhone || !this.visitor.hostName || !this.visitor.hostDepartment || !this.visitor.numberOfVisitors || !this.visitor.appointment || !this.visitor.visitTime || !this.visitor.visitPurpose
		|| !this.visitor.personalDevices || !this.visitor.licensePlate){
			Toast.fail("请检查是否栏位是否输入完成")
			
			return false
		}
		return true
	},
	
	resetForm() {
	      // 重置表单
	    Object.assign(this.$data.visitor, this.$options.data().visitor);
	},
    showIDPicker_true() {
      this.showIDPicker = true;
    },
    cancelIDPicker() {
      this.showIDPicker = false;
    },
    confirmIDPicker(value) {
      this.visitor.validID = value.value;
      this.showIDPicker = false;
    },
	showDatetimePicker_true() {
	      this.datetimePickerShow = true;
	},
	onDatetimeConfirm(value) {
	  this.datetimePickerShow = false;
	  this.visitor.visitTime = this.timeFormat(value);
	},
	onDatetimeCancel() {
	  this.datetimePickerShow = false;
	},
	formatter(type, value) {
	  if (type === 'year') {
		return `${value}年`;
	  }
	  if (type === 'month') {
		return `${value}月`;
	  }
	  if (type === 'day') {
		return `${value}日`;
	  }
	  if (type === 'hour') {
		return `${value}时`;
	  }
	  if (type === 'minute') {
		return `${value}分`;
	  }
	  return value;
	},
	timeFormat(time) { // 时间格式化 2019-09-08
			let year = time.getFullYear();
			let month = time.getMonth() + 1;
			let day = time.getDate();
			let hour = time.getHours();
			let minute = time.getMinutes();
			
			return year + '年' + month + '月' + day + '日'+hour+'时'+minute+'分'
	},
	showPopup() {
		  this.popupVisible = true;
		},
	hidePopup() {
	  this.popupVisible = false;
	},
	onPickerConfirm(value) {
	  this.visitor.visitPurpose = value.text;
	  this.visitor.visitPurposeId = value.value;
	  this.hidePopup();
	},
	showAppointment(){
		this.showAppointment_flag=true;
	},
	cancelAppointment(){
		this.showAppointment_flag=false
	},
	confirmAppointment(value){
		this.visitor.appointment = value.value;
		this.showAppointment_flag=false
	},
	
	showEnterWorkShop(){
		this.showEnterWorkShop_flag=true;
	},
	cancelEnterWorkShop(){
		this.showEnterWorkShop_flag=false
	},
	confirmEnterWorkShop(value){
		this.visitor.enterWorkShop = value.value;
		this.showEnterWorkShop_flag=false
	},
	
	onInput(value) {
	  
	  this.visitor.licensePlate = value.plateNum;
	  if(value.curKey ==="无"){
	  		  this.closeKeyBoard(); // 输入完成后关闭键盘
	  }
	  if (this.visitor.licensePlate.length === this.licensePlateLength) {
	    this.closeKeyBoard(); // 输入完成后关闭键盘
	  }
	},
	changeEnergy(value){
		if(value){
			this.licensePlateLength=8
		}else{
			this.licensePlateLength=7
		}
	},
	closeKeyBoard(){
		this.keyboardOpen = false;
		this.showPlateKeyboard = false;
		setTimeout(()=>{
			this.isSubmitting= false;
		}
		
		,300)
	},
	//添加关闭键盘后防止其他事件做提交
	preventTouchEvent(event) {
	  event.stopPropagation();
	  event.preventDefault();
	},
	showPlateKeyboard_F(){
		this.showPlateKeyboard = true; // 输入完7个字符后关闭键盘
		this.keyboardOpen = true;
		this.isSubmitting= true;
	}
  },
};
</script>
<style>
	.bold-text{
		text-align: left;
		margin: 20px 0;
		font-weight: bold;
		padding: 0 20px;
	}
	input::placeholder {
	  color: #679CDC !important; /* 设置placeholder文本的颜色 */
	}
	
	.keyboard-open {
	  padding-bottom: 80px; /* 添加过渡效果 */
	}
	.keyboard-overlay {
	  position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  background: rgba(0, 0, 0, 0); /* 透明的遮罩层 */
	  z-index: 100; /* 确保它在所有元素之上 */
	}
	.plate-keyboard {
	  position: fixed;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  z-index: 200; /* 设置较大的 z-index，确保键盘显示在遮罩层上方 */
	}
</style>