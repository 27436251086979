import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import qriously from './utils/qriously.js'

import vant from './utils/vant'


//import InputPlateNumber from 'input-plate-number'
//import 'input-plate-number/lib/input-plate-number.css'

//Vue.use(InputPlateNumber)

import InputPlateNum from "./../packages/index";

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
