<template>

  <div class="detail-page">
	  <!-- <template>
	      <qriously :value="qrCodeData" :size="160"/>    
	  </template> -->
    <van-card class="card" :style="{ width: 'calc(100% - 20px)' }">
		
      <template #title>
        <div  class="container record-title" :class="{'container-color-green':record.visitStatus!=='拒绝','container-color-red':record.visitStatus ==='拒绝'}">
        	<span style="float: left;margin-left: 1rem; vertical-align: middle;">{{ record.visitorNumber }}</span>
        	<span style="float: right;margin-right: 1rem; vertical-align: middle;">{{ record.visitStatus }}</span>
        </div>
      </template>
      <template #desc>
        <div class="record-info">
          <label class="label">被访人：</label><span class="text">{{ record.hostName }}</span>
        </div>
        <div class="record-info">
          <label class="label">访问时间：</label><span class="text">{{ record.visitTime }}</span>
        </div>
        <div class="record-info">
          <label class="label">来访事由：</label><span class="text">{{ record.visitPurpose }}</span>
        </div>
        <div class="record-info">
          <label class="label">预约状态：</label><span class="text">{{ record.visitStatus }}</span>
        </div>
		<div v-if="record.cancelReason" class="record-info">
		      <label class="label">取消原因：</label><span class="text">{{ record.cancelReason }}</span>
		</div>
		<div><h2>访客人员</h2></div>
		<van-cell-group>
		   <van-cell
		     v-for="(follower, index) in record.followers"
		     :key="index"
		    :title="`${follower.followerName} - ${follower.followerIdNumber}`"
		     is-link
		   />
		 </van-cell-group>
      </template>
    </van-card>
  </div>
</template>

<script>
import {getVisitDetailById} from '../api/BookDetail.js'
export default {
  data() {
    return {
		qrCodeData:'https://www.taobao.com/',
      record: {
		  hostName:'',
		  followers:[],
	  }, // 从路由参数中获取的预约记录数据
	  showCancelReason:false
    };
  },
  created() {
    // 从路由参数中获取预约记录数据
    this.id = this.$route.params.id;
  },
  mounted() {
  	this.load();
  },
  methods:{
	 async load(){
		const res = await  getVisitDetailById({VisitDetailId:this.id});
		if(res.data.code==200){
			
			const obj = res.data.data;
			
			if(obj.visitStatus == "W"){
				obj.visitStatus ="预约申请中"
			}
			if(obj.visitStatus == "P"){
				obj.visitStatus ="预约完成"
			}
			if(obj.visitStatus == "S"){
				obj.visitStatus ="核验入厂"
			}
			if(obj.visitStatus == "O"){
				obj.visitStatus ="已离场"
			}
			if(obj.visitStatus == "R"){
				obj.visitStatus ="拒绝"
			}
			if(obj.visitStatus == "C"){
				obj.visitStatus ="取消"
			}
			console.log(obj)
			this.record.visitorNumber = obj.visitorNumber;
			this.record.hostName = obj.hostName;
			this.record.visitTime = obj.visitTime;
			this.record.visitPurpose = obj.visitPurpose;
			this.record.visitStatus = obj.visitStatus;
			this.record.cancelReason = obj.cancelReason;
			obj.followers.map(item=>{
				this.record.followers.push({
				  followerName:item.followerName,
				  followerIdNumber:item.followerIdNumber.replace(/^(\d{6})\d{8}(\d{4})$/, '$1********$2'),
				})
			})
			console.log(this.record.followers)
		}
		JSON.stringify(this.record);
	}
  },
};
</script>

<style scoped>
	
	.container {
	  height: 2.5rem;
	  display: flex;
	  justify-content: space-between;
	  align-items: center; /* 垂直居中对齐 */
	  border-top-left-radius: 15px;
	  border-top-right-radius: 15px;
	}
	.container-color-green{
		background-color: #95EC69;
	}
	.container-color-red{
		background-color: red;
	}
	
	.card {
	  margin-bottom: 10px;
	  /*padding: 20px;*/
	  background-color: #f5f5f5;
	  border-radius: 10px;
	  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	}
	.van-card {
	    position: relative;
	    box-sizing: border-box;
		margin: 8px 16px;
	    color: #323233;
	    font-size: 12px;
	    background-color: #fafafa;
	}
.record-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.record-info {
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}

.text {
  flex: 1;
}

.card {
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.qrcode-container {
  text-align: center;
  margin-top: 20px;
}
</style>
