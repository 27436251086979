<template>
  <div>
<!--    <header>
      <h1>Your H5 Home</h1>
      <p>Welcome to your personalized home page!</p>
    </header> -->

    <section class="module" @click="jump('book')">
      <h2>访客预约</h2>
      <p>在此模块中，您可以进行访客预约的操作。</p>
    </section>

    <section class="module" @click="jump('booklist')">
      <h2>预约记录</h2>
      <p>查看您的预约记录和历史信息。</p>
    </section>

    <section class="module" @click="jump('userInfo')">
      <h2>个人信息</h2>
      <p>管理您的个人信息和账户设置。</p>
    </section>

    <footer>
      &copy;  强茂电子（无锡）有限公司
    </footer>
  </div>
</template>

<script>
	import { Toast } from 'vant';
import{getOpenIdByCode,isUserSubscribed,getIsUserInfoFlag} from '/src/api/home.js'
export default {
  name: 'Home',
  data() {
  	return {
  		appid: 'wxff0e54c5d59cb12e',
  		showLoginPic: true,
  		openId:null,
		userInfoFlag:false,
		isGongZhongHaoFlag:true,
  	}
  },
   mounted() {
	  //localStorage.removeItem("openId")
	  let openId = '';
	  //localStorage.setItem('openId', 'oWSGe6DN0LofYoQIqsxSn-R1ZI68');//oWSGe6DN0LofYoQIqsxSn-R1ZI68
	  const storedObj = localStorage.getItem('openId');
	  if(storedObj == "null" || storedObj == undefined ||storedObj == null){
		  if (!openId) {
		    this.getCode();
		  }
	  }else{
		 this.openId = storedObj;
		 this.getIsUserSubscribed(storedObj);
		 
	  }
	  const openId_obj =  localStorage.getItem('openId');
	  if(openId_obj){
		  this.getIsUserInfoFlag(openId_obj);
	  }
	  
  },
  methods: {
	    async getIsUserSubscribed(openId){
			const res = await  isUserSubscribed({openId:openId});
			
			if(res.data.code ===200){
				if(res.data.data==false){
					Toast.fail("请先关注集团公众号：强茂电子!");
					localStorage.removeItem("openId")
					return false;
				}else{
					this.isGongZhongHaoFlag=true;
				}
				return true;
			}
			//localStorage.removeItem("openId")
			return false;
	  },
	  
	  async getIsUserInfoFlag(openId_KK){
		  const res  = await getIsUserInfoFlag({openId:openId_KK});
		  if(res.data.code ===200){
		  	if(res.data.data==true){
				this.userInfoFlag = true;
		  	}
		  }
	  },
	  
	  jump:function(e){
		  if(this.isGongZhongHaoFlag){
			  if(this.userInfoFlag){
				  this.$router.push({path:'/'+e})
			  }else{
				  if(e!="userInfo"){
					  Toast.fail("请先完善个人信息")
				  }
				  this.$router.push({path:'/userInfo'})
			  }
		  }else{
			   Toast.fail("请先关注集团公众号：强茂电子!");
		  }
		  
		  
		  
	  },
	  getCode() {
	    this.code = "";
	    let local = "http://vote.panjit.com.cn/#/";
	    let scope = "snsapi_base"; //静默授权 用户无感知
	    this.code = this.getUrlCode().code;
		
	    if (this.code == null || this.code === "") {
	      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${encodeURIComponent(
	        local
	      )}&response_type=code&scope=${scope}#wechat_redirect`;
	    } else {
	      this.getOpenId(this.code);
	    }
	  },
	  getUrlCode() {
	    let fullUrl = window.location.href;
	    let theRequest = new Object();
	    if (fullUrl.indexOf("?") != -1) {
	      var temp = fullUrl.split("?");
	      let str = temp[1];
	      let strs = str.split("&");
	      for (var i = 0; i < strs.length; i++) {
	        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
	      }
	    }
	    return theRequest;
	  },
	  async getOpenId(code) {
	    let that = this;
		const params ={code:code}
		const res = await getOpenIdByCode(params);
		if(res.data.code==200){
			localStorage.setItem('openId', res.data.data);
			let getOpenIdResult = res.data.data;
			if (getOpenIdResult == "null" || getOpenIdResult == undefined ||getOpenIdResult == null) {
				Toast.fail("未正常获取到您的OpenId，请重新加载")
			} else{
				this.getIsUserSubscribed(res.data.data);
				this.getIsUserInfoFlag(res.data.data);
			}
			
		}
		
	    // this.$api.loginController.getWechatOpenId(code).then(res => {
	    //   if (res.status == 1000) {
	    //     that.openId = res.data.openId;
	    //     storageDB.setItem("openId", that.openId);
	    //   } else {
	    //     Toast(res.message);
	    //   }
	    // });
	  },
  }
}


</script>

<style scoped>
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

header {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1em;
}

section {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
}

p {
  color: #555;
}

.module {
  margin-bottom: 20px;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1em;
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
