//导入axios请求的方法
import request from '../utils/request'


//获得拜访事由列表
export function getVisitPurposeList(){
	return request.get("/getVisitPurposeList");
}

export function getWxUserInfo(params){
	return request.get("/getWxUserInfo",{params})
}


export function submitFrom(visitDetail){
	return request.post('/saveVisitDetail',visitDetail)
}

export function getVisitorInfo(params){
	return request.get("/getVisitorInfo",{params})
}

