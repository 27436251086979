//导入axios
import axios from "axios"

//配置请求的根路径
axios.defaults.baseURL='http://vote.panjit.com.cn:9528/api/book/h5'//

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
	config.headers['Access-Control-Allow-Origin'] = '*'; // 允许跨域请求
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });
  
  
  axios.interceptors.response.use(function (response){
	  return response;
  },function(error){
	  console.log('失败',error);
	  return Promise.reject(error);
  })


  
  export default axios