<!-- <template>
  <div id="app">
    <van-cell-group>
      <!-- 其他字段 
      <van-field
        v-model="licensePlate"
        label="车牌号"
        placeholder="请输入车牌号"
        readonly
        @click="showPlateKeyboard = true"
      />
    </van-cell-group>

    <!-- 车牌号输入组件 
    <input-plate-number
      v-if="showPlateKeyboard"
      @input="onInput"
      @close="showPlateKeyboard = false"  @key-click="onInput" @change-energy="changeEnergy"
    />
  </div>
</template>

<script>
// 引入 InputPlateNumber 组件
import InputPlateNumber from "../components/input-plate-number/src/input-plate-number.vue";

export default {
  data() {
    return {
      licensePlate: '',
      showPlateKeyboard: false, // 控制键盘显示
	  licensePlateLength:7
    };
  },
  components: {
    InputPlateNumber,
  },
  methods: {
    onInput(value) {
	  
      this.licensePlate = value.plateNum;
	  if(value.curKey ==="无"){
	  		  this.showPlateKeyboard = false; // 输入完7个字符后关闭键盘
	  }
      if (this.licensePlate.length === this.licensePlateLength) {
        this.showPlateKeyboard = false; // 输入完7个字符后关闭键盘
      }
    },
	changeEnergy(value){
		if(value){
			this.licensePlateLength=8
		}else{
			this.licensePlateLength=7
		}
	}
  },
};
</script>

<style>
/* 如果需要，可以自定义键盘的样式 */
</style -->

<template>
  <div id="app">
    <!-- <van-nav-bar title="个人信息" left-text="返回" @click-left="goBack" fixed /> -->
<van-form @submit="onSubmit">
    <van-cell-group>
      <van-field
        v-model="visitorName"
        label="访客姓名"
        placeholder="请输入访客姓名" required
        :rules="[{ required: true, message: '请填写用户名' }]"
      ></van-field>

      <van-field
        v-model="phoneNumber"
        label="手机号码"
        placeholder="请输入手机号码"
        type="tel" required
        :rules="[{ required: true, message: '请输入手机号' }, { validator: isValidPhoneNumber, message: '手机号格式错误' }]"
      ></van-field>

      <van-field
        v-model="idCard"
        label="证件号"
        placeholder="支持身份证/台胞证/护照" required
        :rules="[{ required: true, message: '请输入身份证号/台胞证/护照' }]"
		@input="validateIdCard"
      ></van-field>

      <van-field
        v-model="companyName"
        label="公司名称"
        placeholder="请输入公司名称" required
        :rules="[{ required: true, message: '请输入自公司名称,如无请填写无' }]"
      ></van-field>
    </van-cell-group>
    <div class="bold-text">此次个人信息收集仅用于公司内部管理，公司将严格保密。</div>
    <van-button type="primary" @click="onSubmit" round style="width: 80%; margin-top: 20px;">保存信息</van-button>
  </van-form>
  </div>

</template>

<script>
	//导入储存用户信息方法
	import { Toast } from 'vant';
import {getUserInfo, saveUserInfo} from '../api/user.js' 
	
export default {
  data() {
    return {
      visitorName: '',
      phoneNumber: '',
      idCard: '',
      companyName: '',
	  openId:null,
	  pattern: /^\d{11}$/,
    };
  },
  created() {
  },
  mounted(){
	
  },
  methods: {
	  handleVisitorPhoneChange() {
	  		 this.isValidPhoneNumber(this.phoneNumber)
	  },
	  isValidPhoneNumber(phoneNumber) {
	  		// 进行手机格式验证的逻辑
	  		// 你可以使用正则表达式等方式进行验证
	  		// 此处只是简单的示例
	  		return /^1[3-9]\d{9}$/.test(phoneNumber);
	  },
	async load(){
		const storedObj = localStorage.getItem('openId');
		let openId = storedObj;
		this.openId = openId;
		const obj = await getUserInfo({
			openId:this.openId
		});
		if(obj.data){
			const  visitorInfo = obj.data
			this.$data.visitorName = obj.data.data.visitorName
			this.$data.phoneNumber = obj.data.data.phoneNumber
			this.$data.idCard = obj.data.data.idCard
			this.$data.companyName = obj.data.data.companyName
		}
		
	},
    onSubmit() {
      // 在这里处理提交个人信息的逻辑，可以发送到服务器或者进行其他操作
      if (!this.validateForm()) {
        return;
      }
	  return;
      saveUserInfo({
		  openId:this.openId,
		  visitorName:this.visitorName,
		  phoneNumber:this.phoneNumber,
		  idCard:this.idCard,
		  companyName:this.companyName
	  })

      console.log('访客姓名:', this.visitorName);
      console.log('手机号码:', this.phoneNumber);
      console.log('身份证号:', this.idCard);
      console.log('公司名称:', this.companyName);
	  
      Toast.success('个人信息已保存！');
	  this.$router.push({path:'/'})
    },
    goBack() {
      // 处理返回按钮点击事件，可以返回上一页或其他操作
      console.log('返回上一页');
    },
    validateForm() {
      // 进行表单验证，确保必填字段不为空
      if (!this.visitorName || !this.phoneNumber || !this.idCard || !this.companyName) {
		  Toast.fail('请填写所有必填字段')
        return false;
      }
	  if (!this.isValidPhoneNumber(this.phoneNumber)) {
	  	Toast.fail('手机号格式错误')
	  }
      return true;
    },
	validateIdCard(value) {
	      // 只允许输入数字和字母
	      this.idCard = value.replace(/[^a-zA-Z0-9]/g, '');
	},
  },
};
</script>

<style>
#app {
  text-align: center;
  /* margin-top: 60px; */
}
.bold-text{
	margin: 20px 0;
	font-weight: bold;
	padding: 0 20px;
}
</style>
